.container {
    position: relative;
}
.content {
    position: absolute;
    bottom: 3%;
    color: #ffffff;
    padding: 50px;
    text-shadow: 1px 1px 2px rgba(20, 20, 20, 0.5);
}
@media (min-width: 576px) { 
    .card {
        border-radius: 5px;
    }
 }
