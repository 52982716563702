 /* Input style */
 .inputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .inputContainer:focus-within label {
    transform: translate(0, 12px) scale(0.8);
    color: #96a4b3;
  }
  
  .inputContainer .filled {
    transform: translate(0, 12px) scale(0.8);
    color: #96a4b3;
  }
  
  .inputContainer label {
    position: absolute;
    pointer-events: none;
    transform: translate(0, 23px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    color: #96a4b3;
    background-color: #faf8f8;
    font-size: 16px;
    line-height: 1;
    left: 16px;
  }
  
  .inputContainer input {
    height: 64px;
    border-radius: 4px;
    border: none;
    padding: 24px 16px 4px 16px;
    font-size: 16px;
    line-height: 1;
    outline: none;
    box-shadow: none;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
  
  .inputContainer input:focus {
    box-shadow: 0 0 0 2px #dddddd;
  }