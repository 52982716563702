.bed {
    height: 100%;
    position: relative;
}

.content {
    position: absolute;
    bottom: 0%;
}

.imagen {
    height: 400px;
}

.button {
    width: 300px;
}

@media (max-width: 575.98px) { 
    .button {
        width: 100%;
    }
 }